<template>
  <el-card class="box-card">
    <el-collapse v-model="activeName" accordion>
      <el-collapse-item name="1">
        <template slot="title">提醒</template>
        <el-alert
            title="活动进行中商品的需先关闭活动才可进行删除操作"
            type="warning"
            :closable="false">
        </el-alert>
        <el-alert
            title="点击删除按钮可以删除限时折扣商品，该删除商品不影响已经发生的交易"
            type="warning"
            :closable="false">
        </el-alert>
        <el-alert
            title="时间超过折扣活动的结束时间，活动自动结束"
            type="warning"
            :closable="false">
        </el-alert>
      </el-collapse-item>
    </el-collapse>

    <vab-query-form-left-panel :span="24">
      <el-form class="button" :inline="true" :model="queryForm" @submit.native.prevent>
        <el-form-item label="商品名称:">
          <el-input
              v-model.trim="queryForm.name"
              clearable
              placeholder="请输入商品名称"
              size="small"
          />
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" @click="queryData" type="primary" size="small">
            查询
          </el-button>
          <el-button style="margin-right: 10px" icon="el-icon-plus" type="warning" @click="handleEdit" size="small">
            添加商品
          </el-button>
        </el-form-item>
      </el-form>
      <el-radio-group style="margin-left: 20px" v-model="queryForm.type" @change="buttonTab">
        <el-radio-button type="button"
                         v-for="item in button_state"
                         :key="item.id"
                         :label="item.id"
        >
          {{ item.name }}
        </el-radio-button>
      </el-radio-group>
    </vab-query-form-left-panel>

    <el-table
        :data="list"
        @selection-change="setSelectRows">
      <el-table-column
          align="center"
          label="ID"
          prop="id"
          show-overflow-tooltip
      />
      <el-table-column
          align="center"
          label="活动名称"
          prop="name"
          show-overflow-tooltip
      />
      <el-table-column
          align="center"
          label="备注"
          prop="remark"
          show-overflow-tooltip
      />
      <el-table-column align="center" label="开始时间" show-overflow-tooltip>
        <template #default="{ row }">
          {{ row.begin_time | formatDate }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="结束时间" show-overflow-tooltip>
        <template #default="{ row }">
          {{ row.end_time | formatDate }}
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="state"
          label="状态">
      </el-table-column>
      <el-table-column align="center" label="操作" width="120">
        <template #default="{ row }">
          <el-button type="text" @click="timeLimitedDiscountDetails(row)">查看详情</el-button>
          <!--          <el-button type="text" @click="handleDelete(row)">删除</el-button>-->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        :current-page="queryForm.page"
        :layout="layout"
        align="center"
        :page-size="queryForm.pageSize"
        :total="count"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
    <Edit ref="Edit"/>
  </el-card>
</template>
<script>
import {formatDate} from "@/utils/theTimeStamp";
import Edit from './components/Edit'
import {apiGetDiscountList} from "@/request/api";

export default {
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd')
    },
  },
  components: {Edit},
  props: [],
  data() {
    return {
      activeName: '',
      imgShow: true,
      selectRows: '',
      // listLoading: true,
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
        name: '',
        type: 0,
      },
      current: 0,
      button_state: [
        {
          id: 0,
          name: '所有商品'
        },
        {
          id: 1,
          name: '进行中'
        },
        {
          id: 2,
          name: '已结束'
        },
        {
          id: 3,
          name: '已关闭'
        },
      ],
      list: [],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
  },
  mounted() {
  },
  methods: {
    // tab切换
    buttonTab(num) {
      if (num == 1) {
        this.queryForm.type = 1
      } else if (num == 2) {
        this.queryForm.type = 2
      } else if (num == 3) {
        this.queryForm.type = 3
      } else if (num === 0) {
        this.queryForm.type = 0
      } else {
        this.queryForm.type = ''
      }
      this.fetchData()
    },
    timeLimitedDiscountDetails(row) {
      this.$router.push({
        path: 'timeLimitedDiscountDetails',
        query: {
          id: row.id,
        },
      })
    },
    handleEdit(row) {
      if (row.id) {
        this.$router.push({
          path: 'timeLimitedDiscount/revise',
          query: {
            id: row.id,
          },
        })
      } else {
        this.$router.push({
          path: 'timeLimitedDiscount/revise',
        })
      }
    },
    // 删除
    /*handleDelete(row) {
      if (row.id) {
        this.$confirm('你确定要删除当前项吗', null, async () => {
          const {code, message} = await doDelete({id: row.id})
          if (code == '200') {
            this.$message.success(message)
            await this.fetchData()
          } else {
            this.$message.error(message)
            await this.fetchData()
          }
        })
      } else {
        if (this.selectRows.length > 0) {
          const id = this.selectRows.map((item) => item.id).join()
          this.$confirm('你确定要删除选中项吗', null, async () => {
            const {code, message} = await doDelete({id})
            if (code == '200') {
              this.$message.success(message)
              await this.fetchData()
            } else {
              this.$message.error(message)
              await this.fetchData()
            }
          })
        } else {
          this.$message.error('未选中任何行')
          return false
        }
      }
    },*/
    setSelectRows(val) {
      this.selectRows = val
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    queryData() {
      this.queryForm.page = 1
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      apiGetDiscountList(this.queryForm).then(res => {
        if (res.code == 200) {
          this.list = res.data.list
          this.count = res.data.count
        }
      })
      this.listLoading = false
    },

    // 状态
    formatterState: function (row) {
      return row.state == '0' ? '未开始'
          : row.state == '1' ? '进行中'
              : row.state == '2' ? '已结束'
                  : row.state == '3' ? '已结束（手动）'
                      : '暂无'
    },
  }
}

</script>
<style>
.button {
  margin-top: 20px;
}
</style>
